import { Stack } from "@mui/material";
import { type Colleague } from "@src/appV2/Shifts/Shift/types";

import { ColleagueAvatar } from "./ColleagueAvatar";

interface ColleaguesPicturesProps {
  colleagues: Colleague[];
}

/**
 * Shows a row of up to 4 colleague avatars.
 */
export function ColleaguesPictures(props: ColleaguesPicturesProps) {
  const { colleagues } = props;

  return (
    <Stack direction="row" spacing={1}>
      {colleagues.slice(0, 4).map((colleague) => (
        <ColleagueAvatar key={colleague.colleagueUserId} colleague={colleague} />
      ))}
    </Stack>
  );
}
