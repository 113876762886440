import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { ShiftDiscoveryStage } from "@src/appV2/ShiftDiscovery/constants";

export function useIsShiftDiscoveryEnabled(): boolean {
  const shiftDiscoveryStage = useCbhFlag(CbhFeatureFlag.SHIFT_DISCOVERY_STAGE, {
    defaultValue: ShiftDiscoveryStage.OFF,
  });

  return shiftDiscoveryStage !== ShiftDiscoveryStage.OFF;
}
