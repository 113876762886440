import { z } from "zod";

import { breakAcknowledgementSchema, NoteAcknowledgementAction } from "../types";

export const getAcknowledgementsRequestSchema = z
  .object({
    filter: z
      .object({
        action: z.nativeEnum(NoteAcknowledgementAction).optional(),
      })
      .optional(),
  })
  .optional();

export const getAcknowledgementsResponseSchema = z.object({
  data: z.array(
    z.object({
      id: z.string(),
      type: z.string(),
      attributes: breakAcknowledgementSchema,
    })
  ),
});

export const postAcknowledgementRequestSchema = z.object({
  data: z.object({
    attributes: z.object({
      action: z.nativeEnum(NoteAcknowledgementAction),
    }),
    type: z.string(),
  }),
});

export const postAcknowledgementResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.string(),
    attributes: breakAcknowledgementSchema,
  }),
});

export type GetAcknowledgementsResponse = z.infer<typeof getAcknowledgementsResponseSchema>;
export type PostAcknowledgementResponse = z.infer<typeof postAcknowledgementResponseSchema>;
