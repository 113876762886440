import { get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useMutation, type UseMutationResult } from "@tanstack/react-query";

import { type NoteAcknowledgementAction } from "../types";
import {
  getAcknowledgementsRequestSchema,
  type GetAcknowledgementsResponse,
  getAcknowledgementsResponseSchema,
} from "./types";

export interface UseCheckPolicyAcknowledgementProps {
  noteId: string;
  policyAcknowledgementAction: NoteAcknowledgementAction;
}

export function useCheckPolicyAcknowledgement(): UseMutationResult<
  GetAcknowledgementsResponse,
  unknown,
  UseCheckPolicyAcknowledgementProps
> {
  return useMutation({
    mutationFn: async (props: UseCheckPolicyAcknowledgementProps) => {
      const { noteId, policyAcknowledgementAction } = props;

      const response = await get({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/facility-notes/${noteId}/acknowledgements`,
        queryParams: { filter: { action: policyAcknowledgementAction } },
        requestSchema: getAcknowledgementsRequestSchema,
        responseSchema: getAcknowledgementsResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_MISSING_REQUIREMENTS_BY_DATE_FAILURE,
    },
  });
}
