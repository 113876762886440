import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

export enum ExtraWorkedTimeType {
  BREAK = "BREAK",
}

export enum ExtraWorkedTimeRequestStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
}

const extraWorkedTimeRequestsParamsSchema = z.object({
  "filter[type][]": z.nativeEnum(ExtraWorkedTimeType),
  "filter[status][]": z.nativeEnum(ExtraWorkedTimeRequestStatus).optional(),
});

const extraWorkedTimeRequestEntitySchema = z.object({
  id: z.string(),
  type: z.literal("ExtraWorkedTimeRequest"),
  attributes: z.object({
    type: z.nativeEnum(ExtraWorkedTimeType),
    agentId: z.string(),
    facilityId: z.string(),
    shiftId: z.string(),
    reason: z.string(),
    start: z.string(),
    end: z.string(),
    status: z.nativeEnum(ExtraWorkedTimeRequestStatus),
  }),
  meta: z.object({
    computedPay: z.number(),
    computedCharge: z.number(),
    computedExpirationTime: z.string(),
  }),
});

export type ExtraWorkedTimeRequestEntity = z.infer<typeof extraWorkedTimeRequestEntitySchema>;

const extraWorkedTimeRequestsResponseSchema = z.object({
  data: z.array(extraWorkedTimeRequestEntitySchema),
  included: z.array(
    z.union([
      z.object({
        type: z.literal("shift"),
        id: z.string(),
        attributes: z.object({
          start: z.string(),
          end: z.string(),
          pay: z.number(),
          facility: z.object({
            name: z.string(),
            geoLocation: z.object({
              coordinates: z.tuple([z.number(), z.number()]),
              type: z.literal("Point"),
            }),
            state: z.string(),
            tmz: z.string(),
          }),
        }),
      }),
      z.object({
        type: z.literal("facilityProfile"),
        id: z.string(),
        attributes: z.object({
          extraWorkedTimeConfig: z.unknown(),
        }),
      }),
      z.object({
        type: z.literal("facilityUser"),
        id: z.string(),
        attributes: z.object({
          name: z.string(),
        }),
      }),
    ])
  ),
});

export type ExtraWorkedTimeRequestsResponse = z.infer<typeof extraWorkedTimeRequestsResponseSchema>;

export function useExtraWorkedTimeRequests(
  options: UseGetQueryOptions<ExtraWorkedTimeRequestsResponse> = {}
): UseQueryResult<ExtraWorkedTimeRequestsResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/extra-worked-time-requests`,
    requestSchema: extraWorkedTimeRequestsParamsSchema,
    responseSchema: extraWorkedTimeRequestsResponseSchema,
    queryParams: {
      "filter[type][]": ExtraWorkedTimeType.BREAK,
      "filter[status][]": ExtraWorkedTimeRequestStatus.PENDING,
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_EXTRA_WORKED_TIME_REQUESTS_FAILURE,
    },
    ...options,
  });
}
