import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useMutation, type UseMutationResult } from "@tanstack/react-query";

import { type NoteAcknowledgementAction } from "../types";
import {
  postAcknowledgementRequestSchema,
  type PostAcknowledgementResponse,
  postAcknowledgementResponseSchema,
} from "./types";

export interface UsePostPolicyAcknowledgementProps {
  noteId: string;
  policyAcknowledgementAction: NoteAcknowledgementAction;
}

export function usePostPolicyAcknowledgement(): UseMutationResult<
  PostAcknowledgementResponse,
  unknown,
  UsePostPolicyAcknowledgementProps
> {
  return useMutation({
    mutationFn: async (props: UsePostPolicyAcknowledgementProps) => {
      const { noteId, policyAcknowledgementAction } = props;

      const response = await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/facility-notes/${noteId}/acknowledgements`,
        data: {
          data: {
            attributes: { action: policyAcknowledgementAction },
            type: "FacilityNoteAcknowledgement",
          },
        },
        requestSchema: postAcknowledgementRequestSchema,
        responseSchema: postAcknowledgementResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.POST_MANDATORY_BREAK_POLICY_ACKNOWLEDGEMENT_FAILURE,
    },
  });
}
