import { InternalLink, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Stack } from "@mui/material";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useGetShiftColleagues } from "@src/appV2/Shifts/Shift/api/useGetShiftColleagues";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useHistory } from "react-router-dom";

import { DeprecatedGlobalAppV1Paths } from "../../App/paths";
import { useGetShiftSlots } from "../../OpenShifts/api/useGetShiftSlots";
import { ColleaguesPictures } from "./ColleaguesPictures";
import { ColleaguesSummary } from "./ColleaguesSummary";
import { UploadProfilePictureDialog } from "./UploadProfilePictureDialog";

export enum ShiftColleaguesContext {
  CALENDAR = "calendar",
  FACILITY = "facility",
  MY_SHIFTS = "my-shifts",
}

interface ShiftColleaguesProps {
  shift: Shift;
  context: ShiftColleaguesContext;
}

export function ShiftColleagues(props: ShiftColleaguesProps) {
  const { shift, context } = props;

  const isShowRemainingShiftsInTheSlotEnabled = useCbhFlag(
    CbhFeatureFlag.ENABLE_REMAINING_SHIFT_DATA_IN_A_SLOT,
    { defaultValue: false }
  );

  const { data: colleagues } = useGetShiftColleagues({
    shift,
    isShiftCompleted: false,
  });

  const { isSuccess, data: shiftSlots } = useGetShiftSlots(
    {
      workplaceSlots: [
        {
          workplaceUserId: shift.facility.userId ?? "",
          range: {
            start: shift.start,
            end: shift.end,
          },
        },
      ],
    },
    {
      enabled: isDefined(shift.facility.userId),
    }
  );

  const worker = useDefinedWorker();

  const uploadProfilePictureDialogState = useModalState();

  const history = useHistory();

  const shiftSlot = isSuccess ? shiftSlots[0] : undefined;
  const { isProfilePictureUploaded, hideAsColleagueOnShifts, address } = worker;
  const isColleaguesSummaryVisible =
    isDefined(shiftSlot) &&
    (colleagues.length > 0 || (isShowRemainingShiftsInTheSlotEnabled && context !== "my-shifts"));

  const searchParams = new URLSearchParams();
  searchParams.set("referrer", context);
  searchParams.set("isShiftCompleted", "false");
  searchParams.set("shiftId", shift._id);

  if (colleagues.length === 0 && !isColleaguesSummaryVisible) {
    return null;
  }

  return (
    <Stack>
      {isProfilePictureUploaded && colleagues?.length > 0 ? (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box maxWidth={165} overflow="hidden">
            <ColleaguesPictures colleagues={colleagues} />
          </Box>
          <InternalLink
            to={{
              pathname: DeprecatedGlobalAppV1Paths.COLLEAGUE_LIST,
              search: searchParams.toString(),
            }}
            onClick={(event) => {
              // This is sometimes used within a Link component, so we
              // need to stop propagation to not navigate away from the page.
              event.stopPropagation();

              logEvent(APP_V2_USER_EVENTS.OPEN_SHIFT_COLLEAGUES_VIEWED, {
                start: shift.start,
                end: shift.end,
                facility_id: shift.facilityId ?? shift.facility.userId,
                shift_id: shift.shiftId,
                worker_msa: address?.metropolitanStatisticalArea,
                hourly_pay: shift.pay,
                agent_req: shift.agentReq,
                shift_type: shift.name,
                num_open_shifts_in_slot: Object.values(shiftSlot?.stats ?? {}).reduce(
                  (memo, stat) => {
                    return memo + (stat.totalShifts - stat.assignedShifts);
                  },
                  0
                ),
                num_colleagues_shown: shiftSlot?.bookedBy?.length,
                has_missing_documents: false,
                has_work_with_friends_feature_flag_on: true,
                has_profile_picture_uploaded: isProfilePictureUploaded,
                worker_is_hidden_as_colleague_on_shifts: hideAsColleagueOnShifts,
                num_familiar_colleagues_shown: colleagues.filter((colleague) => {
                  return isDefined(colleague.shiftStart);
                }).length,
                num_friends_shown: colleagues.filter((colleague) => colleague.isFriend).length,
                context,
              });

              if (!isProfilePictureUploaded) {
                // Block InternalLink navigation if we're missing a profile picture
                event.preventDefault();
                uploadProfilePictureDialogState.openModal();
              }
            }}
          >
            <Stack direction="row">
              <Text variant="body2">
                {hideAsColleagueOnShifts ? "Show all friends" : "Show all colleagues"}
              </Text>
              <ArrowRightIcon fontSize="small" />
            </Stack>
          </InternalLink>
        </Stack>
      ) : null}

      {isColleaguesSummaryVisible ? (
        <Box sx={{ paddingY: 1 }}>
          <ColleaguesSummary
            shiftSlot={shiftSlot}
            context={context}
            colleagues={colleagues}
            shiftQualification={shift.agentReq}
          />
        </Box>
      ) : null}
      <UploadProfilePictureDialog
        dialogState={uploadProfilePictureDialogState}
        onClose={() => {
          uploadProfilePictureDialogState.closeModal();
        }}
        onCancel={() => {
          uploadProfilePictureDialogState.closeModal();
        }}
        onContinue={() => {
          uploadProfilePictureDialogState.closeModal();

          // FIXME: We should not be using navigation state for this, instead,
          // we should rely on common URL pattern like a query-string
          history.push(DeprecatedGlobalAppV1Paths.PROFILE, {
            isUpdateProfileImageModalOpen: true,
          });
        }}
      />
    </Stack>
  );
}
