import { format, isToday } from "date-fns";

type AllowedFormatString = "EEE" | "EEEE";

/**
 * Formats day name like "Tue" but falls back to "Today" if the date is today
 * Optionally accepts a format string like "EEEE" for full day name
 */
export function formatDayName(date: Date, formatString: AllowedFormatString = "EEE") {
  return isToday(date) ? "Today" : format(date, formatString);
}
