import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { AgentAvatar } from "@src/appV2/Agents/components/AgentAvatar";

import blurredAgentProfilePic from "./blurredAgentProfilePic.jpg";

interface UploadProfilePictureDialogProps {
  dialogState: UseModalState;
  onClose: VoidFunction;
  onCancel: VoidFunction;
  onContinue: VoidFunction;
}

export function UploadProfilePictureDialog(props: UploadProfilePictureDialogProps) {
  const { dialogState, onClose, onContinue, onCancel } = props;

  return (
    <Dialog open={dialogState.modalIsOpen} onClose={onClose}>
      <DialogTitle>See who you will be working with</DialogTitle>
      <DialogContent dividers>
        <Stack alignItems="center" spacing={2}>
          <AgentAvatar profilePictureUrl={blurredAgentProfilePic} size={60} />
          <Text textAlign="center">
            You need to upload a profile picture before you can see who you will be working with.
          </Text>
          <Text textAlign="center" fontStyle="italic" variant="body2">
            You can disable this at any time under My Account &gt; Profile.
          </Text>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button fullWidth variant="contained" onClick={onContinue}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
