import { z } from "zod";

export enum NoteAcknowledgementAction {
  BOOK_SHIFT = "BOOK_SHIFT",
  CLOCK_IN = "CLOCK_IN",
  START_RATE_NEGOTIATION = "START_RATE_NEGOTIATION",
}

export const breakAcknowledgementSchema = z.object({
  agentId: z.string(),
  facilityNoteSha1: z.string(),
  action: z.nativeEnum(NoteAcknowledgementAction),
});

export type BreakAcknowledgement = z.infer<typeof breakAcknowledgementSchema>;
