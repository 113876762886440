import { Text, type UseModalState } from "@clipboard-health/ui-react";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Stack } from "@mui/material";

import { PolicyDialog } from "../PolicyDialog/PolicyDialog";
import { NoteAcknowledgementAction } from "./types";

interface MandatoryBreakPolicyDialogProps {
  modalState: UseModalState;
  contentLines: string[];
  acknowledgementAction: NoteAcknowledgementAction;
  onClose: () => void;
  onContinue: () => void;
}

const ContinueButtonTextVariants = {
  [NoteAcknowledgementAction.BOOK_SHIFT]: "Continue with booking",
  [NoteAcknowledgementAction.CLOCK_IN]: "Continue with clock in",
  [NoteAcknowledgementAction.START_RATE_NEGOTIATION]: "Continue",
};

export function MandatoryBreakPolicyDialog(props: MandatoryBreakPolicyDialogProps) {
  const { modalState, contentLines, acknowledgementAction, onClose, onContinue } = props;

  return (
    <PolicyDialog
      modalState={modalState}
      dialogTitle="Mandatory Break Policy"
      content={
        <Stack spacing={2}>
          <Text>
            This facility requires a 30-minute unpaid break and enforces the following policy:
          </Text>
          {contentLines?.map((contentLine) => (
            <Text key={contentLine} fontStyle="italic">
              {contentLine}
            </Text>
          ))}
          <Text>
            If you work through the break, you will have to submit additional documentation. The
            payment for the additional worked time may be withheld for up to 3 business days while
            the break payment request undergoes a separate approval process.
          </Text>
        </Stack>
      }
      successButtonText={ContinueButtonTextVariants[acknowledgementAction] ?? "Continue"}
      contentIcon={<RestaurantIcon sx={{ fontSize: "4rem" }} />}
      onSuccess={onContinue}
      onClose={onClose}
    />
  );
}
