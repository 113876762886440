import { type DateRange } from "@src/appV2/lib/Calendar/types";
import { format } from "date-fns";

export function formatTimeRange(dateRange: DateRange): string {
  const formatTime = (date: Date) => {
    return format(date, "h:mm a").toLowerCase();
  };

  const startTime = formatTime(dateRange.startDate);
  const endTime = formatTime(dateRange.endDate);

  return `${startTime} - ${endTime}`;
}
