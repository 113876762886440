import { formatDate } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { type Colleague } from "@src/appV2/Shifts/Shift/types";
import { isToday, isYesterday, parseISO } from "date-fns";

import { type ShiftSlot } from "../../Facilities/types";
import { type ShiftColleaguesContext } from "./ShiftColleagues";

function formatShiftStartDate(date: string): string {
  if (isToday(parseISO(date))) {
    return "today";
  }

  if (isYesterday(parseISO(date))) {
    return "yesterday";
  }

  return `on ${formatDate(date)}`;
}

function getShiftsStatsText(stats: ShiftSlot["stats"], isAssigned: boolean): string {
  return Object.entries(stats)
    .map(([key, value]) => ({ ...value, qualification: key }))
    .filter((stat) => {
      return isAssigned ? stat.assignedShifts > 0 : stat.assignedShifts < stat.totalShifts;
    })
    .sort((statA, statB) => {
      return isAssigned
        ? statA.assignedShifts - statB.assignedShifts
        : statB.totalShifts - statB.assignedShifts - (statA.totalShifts - statA.assignedShifts);
    })
    .map((stat, index, array) => {
      const { qualification, totalShifts, assignedShifts } = stat;

      const count = isAssigned ? assignedShifts : totalShifts - assignedShifts;
      const qualificationText = count > 1 ? `${qualification}s` : `${qualification}`;
      const countText = count > 2 ? "2+" : count;

      if (index === 0) {
        return `${countText} ${qualificationText}`;
      }

      if (index === array.length - 1) {
        return ` and ${countText} ${qualificationText}`;
      }

      return `, ${countText} ${qualificationText}`;
    })
    .join("");
}

interface ColleaguesSummaryProps {
  shiftSlot: ShiftSlot;
  colleagues: Colleague[];
  context: ShiftColleaguesContext;
  shiftQualification?: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function ColleaguesSummary(props: ColleaguesSummaryProps) {
  const { shiftSlot, colleagues, context, shiftQualification } = props;

  const isShowRemainingShiftsInTheSlotEnabled = useCbhFlag(
    CbhFeatureFlag.ENABLE_REMAINING_SHIFT_DATA_IN_A_SLOT,
    { defaultValue: false }
  );

  const statsWithoutCurrentUser = { ...shiftSlot.stats };

  if (
    context === "my-shifts" &&
    isDefined(shiftQualification) &&
    isDefined(shiftSlot.stats[shiftQualification]?.assignedShifts)
  ) {
    statsWithoutCurrentUser[shiftQualification].assignedShifts -= 1;
    statsWithoutCurrentUser[shiftQualification].totalShifts -= 1;
  }

  if (colleagues.length === 0) {
    return (
      <Stack>
        <Text>Looking for {getShiftsStatsText(shiftSlot.stats, false)}</Text>
        <Text variant="body2">Be the first to book this shift</Text>
      </Stack>
    );
  }

  const subtitle =
    isShowRemainingShiftsInTheSlotEnabled && context !== "my-shifts"
      ? `Still Looking for ${getShiftsStatsText(statsWithoutCurrentUser, false)}`
      : undefined;

  const friends = colleagues.filter((colleague) => colleague.isFriend);

  if (friends.length > 0) {
    const adverb = context === "my-shifts" ? "also" : "already";

    return (
      <Stack>
        <Text>
          {friends.length === 1
            ? `${friends[0].colleagueName} ${adverb} booked this shift`
            : `${friends.length} friends ${adverb} booked this shift`}
        </Text>
        {isDefined(subtitle) ? <Text variant="body2">{subtitle}</Text> : null}
      </Stack>
    );
  }

  const recentColleagues = colleagues.filter((colleague) => colleague.shiftStart);

  if (recentColleagues.length > 0) {
    const [recentColleague] = recentColleagues;
    const shiftStart = isDefined(recentColleague.shiftStart)
      ? formatShiftStartDate(recentColleague.shiftStart)
      : undefined;

    return (
      <Stack>
        <Text>
          {recentColleagues.length === 1
            ? `You worked with ${recentColleague.colleagueName} ${shiftStart ?? ""}`
            : `You worked with ${recentColleagues.length} colleagues recently`}
        </Text>
        {isDefined(subtitle) ? <Text variant="body2">{subtitle}</Text> : null}
      </Stack>
    );
  }

  const shiftStatsText = getShiftsStatsText(shiftSlot.stats, true);
  const totalNumberOfShiftsBooked = Object.values(statsWithoutCurrentUser).reduce((memo, stat) => {
    return memo + stat.assignedShifts;
  }, 0);
  const verbToUse = totalNumberOfShiftsBooked > 1 ? "have" : "has";

  return (
    <Stack>
      <Text>
        {shiftStatsText} {verbToUse} booked this shift
      </Text>
      {isDefined(subtitle) ? <Text variant="body2">{subtitle}</Text> : null}
    </Stack>
  );
}
