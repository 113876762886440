import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import { Badge } from "@mui/material";
import { AgentAvatar } from "@src/appV2/Agents/components/AgentAvatar";
import { type Colleague } from "@src/appV2/Shifts/Shift/types";

interface ColleagueAvatarProps {
  colleague: Colleague;
}

export function ColleagueAvatar(props: ColleagueAvatarProps) {
  const { colleague } = props;

  if (colleague.isFriend) {
    return (
      <Badge
        badgeContent={<PeopleOutlineOutlinedIcon sx={{ width: 8, height: 8 }} />}
        overlap="circular"
        color="primary"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <AgentAvatar userId={colleague.colleagueUserId} userName={colleague.colleagueName} />
      </Badge>
    );
  }

  return <AgentAvatar userId={colleague.colleagueUserId} userName={colleague.colleagueName} />;
}
