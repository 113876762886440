import { type DateRange } from "@src/appV2/lib";
import { formatDuration, intervalToDuration } from "date-fns";

/**
 * Outputs duration of a date range in the format of "X hours Y minutes"
 */
export function formatDateRangeDuration(dateRange: DateRange) {
  const { startDate, endDate } = dateRange;

  const interval = intervalToDuration({
    start: startDate.getTime(),
    end: endDate.getTime(),
  });

  return formatDuration(interval, { format: ["hours", "minutes"] });
}
